<template>
  <div id="branding-bar">
    <img src="@/assets/branding-test-beta.png" alt="Logo" class="beta" />
  </div>

  <div id="app">
    <div class="container-spacer-top"></div>

    <div class="container-spacer-install-nom">
      <img
        :src="require('@/assets/install-nom-test-flight.png')"
        alt="Image 6"
        class="install-nom"
        @click="scrollToEnterDetails"
      />
    </div>

    <img
      :src="require('@/assets/screenshots/splash.png')"
      alt="Screenshot 1"
      class="screenshots"
    />

    <div class="container-spacer"></div>

    <img
      :src="require('@/assets/screenshots/listingdetail.png')"
      alt="Screenshot 2"
      class="screenshots offscreenLeft"
    />

    <div class="container-spacer"></div>

    <img
      :src="require('@/assets/screenshots/search.png')"
      alt="Screenshot 3"
      class="screenshots offscreenRight"
    />

    <div class="container-spacer"></div>

    <img
      :src="require('@/assets/screenshots/profile.png')"
      alt="Screenshot 4"
      class="screenshots offscreenLeft"
    />

    <div class="container-spacer"></div>

    <img
      :src="require('@/assets/screenshots/map.png')"
      alt="Screenshot 5"
      class="screenshots offscreenRight"
    />
    <div class="container-spacer"></div>

    <img
      :src="require('@/assets/screenshots/modeselect.png')"
      alt="Screenshot 6"
      class="screenshots offscreenLeft"
    />

    <div id="title-enter-your-details" class="container-spacer-enter-details">
      <img
        src="@/assets/enter-your-details.png"
        alt="Logo"
        class="enter-your-details"
      />
    </div>

    <div class="container-form">
      <input
        ref="firstname"
        v-model="formData.firstname"
        @input="handleInputFirstName"
        name="firstname"
        type="text"
        placeholder="First Name"
        required
        class="modern-input"
      />

      <div class="container-spacer-form"></div>

      <input
        ref="lastname"
        v-model="formData.lastname"
        @input="handleInputLastName"
        name="lastname"
        type="text"
        placeholder="Last Name"
        required
        class="modern-input"
      />

      <div class="container-spacer-form"></div>

      <input
        ref="phone"
        v-model="formData.phone"
        @input="handleInputPhoneNumber"
        name="phone"
        type="tel"
        placeholder="Phone Number"
        required
        class="modern-input"
      />

      <div class="container-spacer-form"></div>

      <input
        ref="email"
        v-model="formData.email"
        @input="handleInputEmail"
        name="email"
        type="email"
        placeholder="Email"
        required
        class="modern-input"
      />

      <div class="container-spacer-form"></div>

      <input
        ref="zipcode"
        v-model="formData.zipcode"
        @input="handleInputZipcode"
        name="zipcode"
        type="tel"
        placeholder="Zipcode"
        required
        class="modern-input"
      />

      <div class="container-spacer-form-button-top">
        <p v-if="errorMessage" class="msgError">
          {{ errorMessageDescription }}
        </p>
      </div>

      <button
        type="submit"
        :disabled="loading"
        class="submit-button"
        @click="callTheSignUpThings"
      >
        SIGN UP
      </button>

      <div class="container-spacer-form-button-bot"></div>

      <div v-if="loading" class="loading-view">
        <div class="spinner"></div>
        <p class="loading-message">Confirming Your Beta Access...</p>
      </div>
    </div>
    <!-- container-form -->

    <div class="container-closing">
      <div class="closing">
        NOM The App is currently beta testing on iOS utilizing Apple's
        TestFlight, a registered trademark of Apple Inc. TestFlight's logo, iOS
        platform, and iPhone are also registered trademarks of Apple Inc. Your
        active participation in testing this application will contribute to
        gathering valuable feedback and enhancing the overall user experience.
      </div>
    </div>
    <!-- container-closing -->

    <div>
      <p class="copyright">
        Copyright &copy; 2024 NOM LLC. All rights reserved.
      </p>
    </div>

    <div class="container-spacer-bot"></div>
  </div>
  <!-- app -->
</template>

<style>
#app {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#branding-bar {
  overflow-x: hidden;
  overflow-y: hidden;
  width: 375px; /* width: 100%; */
  height: 75px; /* height: 7%; 8/
  /* Adjust height as needed */
  background-color: rgba(51, 51, 51, 0);
  /* Example background color with transparency */
  color: white;
  /* Example text color */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  /* Fix the position */
  top: 0;
  /* Place it at the top */
  z-index: 1000;
  /* Ensure it's above other elements */
  /* backdrop-filter: blur(6px); */
  /* Apply blur effect to the background */
  /* border: 1px solid rgba(0, 139, 53, 0.5);   */
}
.beta {
  width: 80px; /*  width: 23%; */
  /* Adjust width as needed */
  height: auto;
  /* Automatically adjust height to maintain aspect ratio */
  margin-top: 15px;
  margin-left: 10px;
  /* border: 1px solid rgba(0, 139, 53, 0.5);  */
}
.container-spacer-install-nom {
  width: 375px; /* width: 100%; */
  /* Adjust width as needed */
  height: 100px; /*height: calc(31vw); */
  /* Automatically adjust height to maintain aspect ratio */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* Center items vertically */
  /* border: 1px solid rgba(234, 255, 0, 0.5);  */
}
.container-spacer-top {
  display: flex;
  width: 375px; /* width: 100%; */
  /* Adjust width as needed */
  height: 55px; /* height: calc(14vw); */
  /* border: 1px solid rgba(0, 102, 255, 0.5); */
}
.install-nom {
  width: 175px; /* width: 48%; */
  /* Adjust width as needed */
  height: auto;
  /* Automatically adjust height to maintain aspect ratio */
  margin-top: 1%;
  margin-right: 4.5%;
  margin-left: 0%;
  opacity: 0;
  /* border: 1px solid rgba(0, 139, 53, 0.5);  */
}
.screenshots {
  width: 350px; /*width: 90%; */
  /* Adjust as needed */
  height: auto;
  /* Automatically adjust height to maintain aspect ratio */
  object-fit: contain;
  /* Maintain aspect ratio while fitting within the container */
  margin-bottom: 0%;
  /* Add spacing between images */
  margin-top: 0%;
  /* Add spacing between images */
  margin-left: 100;
  /* overflow: hidden; */
  /* Clip any overflowing content */
  clip-path: inset(0% 0% 0% 0%);
  /* Clip 10% off the top and bottom */
  /*border: 1px solid rgba(0, 139, 53, 0.5); */
  opacity: 0;
  /* border: 1px solid rgba(234, 255, 0, 0.5);  */
}
.offscreenLeft {
  transform: translateX(-90%);
  /* transform: scale(0.5) */
}
.offscreenRight {
  transform: translateX(90%);
  /* transform: scale(0.5) */
}
.container-spacer {
  width: 370px; /*width: 100%; */
  /* Adjust width as needed */
  height: 80px; /*height: calc(23vw); */
  /* Automatically adjust height to maintain aspect ratio */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* Center items vertically */
  /* border: 1px solid rgba(255, 0, 0, 0.5);  */
}
.container-spacer-form-button-top {
  width: 240px; /* width: 100%; */
  /* Adjust width as needed */
  height: 45px; /* height: calc(5vw); 8?
  /* Automatically adjust height to maintain aspect ratio */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* Center items vertically */
  /* border: 1px solid rgba(0, 221, 255, 0.5);   */
}
.container-form {
  width: 370px; /* width: 100%; */
  height: 380px; /* height: calc(95vw); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* Center items vertically */
  position: relative;
  opacity: 0;
  /* Ensure this is positioned relative */
  /* border: 1px solid rgba(255, 0, 217, 0.5);  */
}
/* TEXT FIELDS */
.modern-input {
  width: 220px; /* width: 60%; */
  /* Adjust width as needed */
  height: 38px; /* height: calc(12vw); */
  /* Automatically adjust height to maintain aspect ratio */
  appearance: none;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #ffffff6f;
  font-size: 16px; /*font-size: 1rem; */ /* set to 16px to avoid ios zooming when the keyboardis raised */
  line-height: 20px; /*line-height: calc(12vw); 8?
  /* Set line-height to match the height */
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 10px;
  color: rgb(255, 255, 255);
  padding: 8px; /* padding: 0.5rem; */
}
.modern-input:focus {
  border-color: rgba(55, 132, 255, 0.8);
  outline: none;
  box-shadow: 0 0 0 3px rgba(94, 114, 228, 0.25); /* box-shadow: 0 0 0 0.2rem rgba(94, 114, 228, 0.25); */
  /* Add focus effect */
  transform: scale(1);
  /* Slightly increase the size on hover */
}
.modern-input::placeholder {
  color: #ffffff6f;
  /* Adjust placeholder color */
  line-height: inherit;
  /* Inherit line-height to center placeholder text */
  text-indent: 15px; /*text-indent: 5%; */
}
.modern-input:disabled {
  background-color: #e9ecef;
  /* Adjust disabled state color */
  opacity: 0.8;
}
.container-spacer-form {
  width: 370px; /* width: 100%; */
  /* Adjust width as needed */
  height: 8px;
  /* height: calc(3vw); */
  /* Automatically adjust height to maintain aspect ratio */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* Center items vertically */
  /* border: 1px solid rgba(255, 0, 0, 0.5); */
}
.container-spacer-form-button-bot {
  width: 370px; /* width: 100%; */
  /* Adjust width as needed */
  height: 17px; /* height: calc(5vw); */
  /* Automatically adjust height to maintain aspect ratio */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* Center items vertically */
  /* border: 1px solid rgba(200, 255, 0, 0.5); */
}
.container-spacer-enter-details {
  width: 375px; /* width: 100%; */
  /* Adjust width as needed */
  height: 120px; /* height: calc(31vw); */
  /* Automatically adjust height to maintain aspect ratio */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  /* Center items vertically */
  /* border: 1px solid rgb(255, 255, 255);  */
}
.enter-your-details {
  width: 175px; /* width: calc(48vw); */
  /* Adjust width as needed */
  height: auto;
  /* Automatically adjust height to maintain aspect ratio */
  margin-top: 37px; /* margin-top: calc(10vw); */
  margin-right: 40px; /* margin-right: calc(11vw); 8? */
  margin-left: 0%;
}
/* SUBMIT BUTTON */
.submit-button {
  width: 130px; /* width: calc(40vw); */
  /* Adjust width as needed */
  height: 55px; /* height: calc(16vw); */
  /* Automatically adjust height to maintain aspect ratio */
  background-color: rgba(55, 132, 255, 1);
  /* Rub Blue color */
  color: white;
  /* White text */
  border: none;
  /* Remove border */
  border-radius: 5px;
  /* Rounded corners */
  cursor: pointer;
  /* Pointer cursor on hover */
  font-size: 16px;
  /* Font size */
  transition: background-color 0.3s ease, transform 0.3s ease;
  /* Smooth transition for background color and transform */
  font-family: Arial;
  font-weight: bold;
  /* Specify bold font weight */
  color: #ffffff;
  /* Adjust text color */
}
.submit-button:hover {
  background-color: rgba(55, 140, 255, 1);
  /* Darker background color on hover */
  transform: scale(1.05);
  /* Slightly increase the size on hover */
}
.submit-button:disabled {
  background-color: #888;
  /* Grey background color when disabled */
  cursor: not-allowed;
  /* Not-allowed cursor when disabled */
  transform: none;
  /* No transform when disabled */
}
.loading-view {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0);
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  /* Ensure it's above the form elements */
  width: 100%;
  /* Adjust width as needed */
  height: 100%;
  /* Adjust height as needed */
  backdrop-filter: blur(4px);
  /* Adjust the blur radius as needed */
}
.spinner {
  /* Replace with your spinner styles */
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: rgba(55, 140, 255, 1);
  border-radius: 50%;
  width: 45px; /* width: calc(15vw); */
  height: 45px; /* height: calc(15vw); */
  animation: spin 1s linear infinite;
}
.loading-message {
  margin-top: 14px; /* margin-top: 1rem; */
  /* Adjust spacing between spinner and text */
  font-size: 16px; /* font-size: 1.2rem; */
  /* Adjust font size as needed */
  font-family: Arial;
  font-weight: bold;
  /* Specify bold font weight */
  color: #ffffff;
  /* Adjust text color */
}
.msgError {
  font-size: 12px; /* font-size: 2.7vw; */
  color: #ff0000;
  font-family: "Arial"; /* Fallback font family */
  text-align: justify;
  font-style: italic; /* Added to make the text italic */
}
.container-closing {
  width: 300px; /* width: calc(80vw); */
  height: 100px;
  /* Adjust height as needed */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  /* Center-align text */
  position: relative;
  background-color: #000000;
  /* Example background color */
  /* border: 1px solid rgba(255, 106, 0, 0.5);  */
}
.closing {
  font-size: 10px; /* font-size: 2.7vw; */
  color: #666;
  font-family: "Arial", sans-serif;
  /* Fallback font family */
  text-align: justify;
}
.copyright {
  font-size: 10px; /* font-size: 2.7vw; */
  color: #666;
  font-family: "Arial", sans-serif;
  /* Fallback font family */
  text-align: center;
  /* Optional: Center align the text */
  /* border: 1px solid rgba(255, 106, 0, 0.5);  */
}
.container-spacer-bot {
  display: flex;
  width: 370px; /* width: 100%; */
  /* Adjust width as needed */
  height: 25px; /*height: calc(5vw); */
  /* border: 1px solid rgba(0, 217, 255, 0.834); */
}
/* ANIMATIONS */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>

<script>
export default {
  name: "App",
  mounted() {
    // Scroll to the top of the page
    //window.scrollTo(0, 0);

    //inital fade in for INSTALL NOM
    setTimeout(() => {
      let installLogo = document.querySelector(".install-nom");
      installLogo.style.opacity = 1; // Set opacity to 1 for fade-in effect
      installLogo.style.transition = "opacity 1.0s ease"; // Transition duration and timing function for opacity
    }, 0);

    setTimeout(() => {
      let options = {
        root: null, // Use the viewport as the container
        rootMargin: "0px",
        threshold: 0.05, // Trigger the callback when 10% of the element is visible
      };

      let callback = (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            console.log("Element is intersecting:", entry.target);
            observer.log;

            switch (true) {
              case entry.target.classList.contains("screenshots"):
                entry.target.style.transition = "transform 0.350s ease";
                entry.target.style.transform = "translateX(0%)";
                entry.target.style.opacity = "1";
                break;

              case entry.target.classList.contains("container-form"):
                entry.target.style.opacity = "1";
                entry.target.style.transition = "opacity 1.0s linear";
                break;

              case entry.target.classList.contains("submit-button"):
                entry.target.style.opacity = "1";
                entry.target.style.transition = "opacity 1.0s linear";
                break;

              case entry.target.classList.contains(
                "container-spacer-enter-details"
              ):
                entry.target.style.opacity = "1";
                entry.target.style.transition = "opacity 1.0s linear";
                break;

              default:
                break;
            }
          } else {
            console.log("Element is not intersecting:", entry.target);
            // Perform actions when the element is not intersecting
          }
        });
      };

      let observer = new IntersectionObserver(callback, options);

      let elements = document.querySelectorAll(".screenshots");
      elements.forEach((element) => {
        observer.observe(element);
      });

      let containerForm = document.querySelector(".container-form");
      observer.observe(containerForm);

      let submitButton = document.querySelector(".submit-button");
      observer.observe(submitButton);

      let imageEnterDetails = document.querySelector(
        ".container-spacer-enter-details"
      );
      observer.observe(imageEnterDetails);

      let copyright = document.querySelector(".copyright");
      observer.observe(copyright);

      let closing = document.querySelector(".closing");
      observer.observe(closing);
    }, 10);
  },

  data() {
    return {
      formData: {
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        zipcode: "",
      },
      loading: false,
      successMessage: false,
      errorMessage: false,
    };
  },
  methods: {
    animateScreenshots() {
      let screenshots = document.querySelectorAll(".screenshots");
      screenshots.forEach((screenshot) => {
        screenshot.style.transition = "transform 1.0s ease"; // Transition duration and timing function
        screenshot.style.transform = "translateX(0%)"; // Initial transform state (starting position)
      });
    },
    scrollToEnterDetails() {
      console.log("called scrollToEnterDetails()");
      let element = document.getElementById("title-enter-your-details");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    callTheSignUpThings() {
      this.loadingView(() => {
        this.validatoriOSiPhone(() => {
          this.validatorEmptyFields(() => {
            this.validatorZipCode(() => {
              this.validatorPhoneNumber(() => {
                this.validatorEmail(() => {
                  this.apiSendData();
                });
              });
            });
          });
        });
      });
    },
    loadingView(completion) {
      this.loading = true;
      this.successMessage = false;
      this.errorMessage = false;

      if (typeof completion === "function") {
        completion();
      }
    },
    validatorEmptyFields(completion) {
      if (this.checkerEmptyFields()) {
        //SUCCESS
        if (typeof completion === "function") {
          console.log(
            "SUCCESS -- All Fields Have User Data -- validatorEmptyFields()"
          );
          completion();
        }
      } else {
        //FAIL
        this.loading = false;
        console.log(
          "ERROR -- One of the fields is empty -- validatorEmptyFields()"
        );
      }
    },
    checkerEmptyFields() {
      for (let key in this.formData) {
        if (!this.formData[key]) {
          console.log(`${key} is empty`);

          switch (key) {
            case "firstname":
              this.errorMessageDescription = `*Your first name is missing.`;
              this.errorMessage = true;
              this.loading = false;
              break;

            case "lastname":
              this.errorMessageDescription = `*Your last name is missing.`;
              this.errorMessage = true;
              this.loading = false;
              break;

            case "phone":
              this.errorMessageDescription = `*Your phone number is missing.`;
              this.errorMessage = true;
              this.loading = false;
              break;

            case "email":
              this.errorMessageDescription = `*Your email address is missing.`;
              this.errorMessage = true;
              this.loading = false;
              break;

            case "zipcode":
              this.errorMessageDescription = `*Your zipcode is missing.`;
              this.errorMessage = true;
              this.loading = false;
              break;

            default:
              this.errorMessageDescription = `*The ${key} field is empty.`;
              // Default action for other fields
              break;
          }
          // let element = this.$refs[key];

          // if (element) {
          //   element.style.borderColor = 'red';
          // } else {
          //   console.log('cant find the fucking element')
          // }
          return false; // Exit the function early if any field is empty
        }
      }
      return true; // All fields are filled, continue with submission
    },
    validatorEmail(completion) {
      if (this.checkerEmail(this.formData.email)) {
        //SUCCESS
        console.log("SUCCESS -- Email is Valid -- validatorEmail()");

        if (typeof completion === "function") {
          completion();
        }
      } else {
        console.log("ERROR -- Invalid Email Format -- validatorEmail()");
        this.errorMessageDescription = "*Please enter a valid email address";
        this.errorMessage = true;
        this.loading = false;
      }
    },
    checkerEmail(email) {
      let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailPattern.test(email);
    },
    validatoriOSiPhone(completion) {
      if (this.checkeriOSiPhone()) {
        //SUCCESS
        if (typeof completion === "function") {
          console.log("SUCCESS -- user is on ios -- validatoriOSiPhone()");
          completion();
        }
      } else {
        //FAIL
        this.errorMessageDescription =
          "*You can only sign up from an iPhone device.";
        this.errorMessage = true;
        this.loading = false;
        console.log("ERROR -- user is NOT on ios -- validatoriOSiPhone()");
      }
    },
    checkeriOSiPhone() {
      let userAgent = window.navigator.userAgent.toLowerCase();
      let isIOS = /iphone/.test(userAgent);
      return isIOS;
    },
    navToAppDownloadLocation() {
      console.log(
        "STATUS -- Navigating to App Download URL -- navToAppDownloadLocation()"
      );
      window.location.href = "https://testflight.apple.com/join/BIAKkYzG";
    },
    handleInputFirstName() {
      this.errorMessage = false;
      this.errorMessageDescription = "";
    },
    handleInputLastName() {
      this.errorMessage = false;
      this.errorMessageDescription = "";
    },
    handleInputPhoneNumber(event) {
      this.errorMessage = false;
      this.errorMessageDescription = "";

      let input = event.target.value.replace(/\D/g, "");
      let formatted = "";
      if (input.length > 0) {
        formatted += "(";
      }
      if (input.length > 3) {
        formatted += input.substring(0, 3) + ") ";
      } else {
        formatted += input;
      }
      if (input.length > 6) {
        formatted += input.substring(3, 6) + "-";
      } else {
        formatted += input.substring(3);
      }
      formatted += input.substring(6, 10);
      this.formData.phone = formatted;
    },
    handleInputEmail() {
      this.errorMessage = false;
      this.errorMessageDescription = "";
    },
    handleInputZipcode(event) {
      this.errorMessage = false;
      this.errorMessageDescription = "";

      let sanitizedInput = event.target.value.replace(/\D/g, "");

      let length = sanitizedInput.slice(0, 5);

      this.formData.zipcode = String(length); // Ensure zipcode is a string
    },
    apiSendData() {
      let dict = new FormData();

      for (let key in this.formData) {
        dict.append(key, this.formData[key]);
      }

      let url =
        "https://script.google.com/macros/s/AKfycbw378xQzdDwq4_vIRWcgQP8M0LVp7_7ROcbPCcMaYwEnZbXT56E4LoT3vJMZ11z8zOJ-g/exec";

      fetch(url, { method: "POST", body: dict })
        .then((response) => this.showSuccessMessage(response))
        .catch((error) => this.showErrorMessage(error));
    },
    showSuccessMessage(response) {
      console.log(
        "SUCCESS -- Sign Up Data Successfully Sent -- showSuccessMessage()"
      );
      console.log(response);
      this.loading = false;
      this.resetData();
      this.successMessage = true;
      this.navToAppDownloadLocation();
    },
    showErrorMessage(error) {
      console.log(
        "ERROR -- Sign Up Data Not Successfully Sent -- showErrorMessage()"
      );
      console.error("Error!", error.message);
      this.loading = false;
      this.errorMessage = true;
    },
    resetData() {
      console.log("STATUS -- Resetting Form Data -- resetData()");
      this.formData.firstname = "";
      this.formData.lastname = "";
      this.formData.phone = "";
      this.formData.email = "";
      this.formData.zipcode = "";
    },
    validatorPhoneNumber(completion) {
      if (this.checkerPhoneNumber()) {
        //SUCCESS
        if (typeof completion === "function") {
          console.log(
            "SUCCESS -- This is a valid phone number -- validatorPhoneNumber()"
          );
          completion();
        }
      } else {
        //FAIL
        console.log(
          "ERROR -- This is not a valid phone number -- validatorPhoneNumber()"
        );
        this.errorMessageDescription = `*Please enter a valid U.S phone number`;
        this.errorMessage = true;
        this.loading = false;
      }
    },
    checkerPhoneNumber() {
      let sanitizedNumber = this.formData.phone.replace(/\D/g, "");

      // Validate the sanitized input
      let phonePattern = /^\d{10}$/;

      if (!phonePattern.test(sanitizedNumber)) {
        return false;
      }

      let firstThreeDigits555 = sanitizedNumber.slice(0, 3);
      if (firstThreeDigits555 === "555") {
        console.log(
          "STATUS -- User Entered 555 as an area code -- checkerPhoneNumber()"
        );
        return false;
      }

      let middleThreeDigits555 = sanitizedNumber.slice(3, 6);
      if (middleThreeDigits555 === "555") {
        console.log(
          "STATUS -- User Entered 555 as local code -- checkerPhoneNumber()"
        );
        return false;
      }

      let lastFour5555 = sanitizedNumber.slice(6, 10);
      console.log(lastFour5555);
      if (lastFour5555 === "5555") {
        console.log(
          "STATUS -- User Entered 5555 as last 4 digits -- checkerPhoneNumber()"
        );
        return false;
      }

      let firstThreeDigits123 = sanitizedNumber.slice(0, 3);
      if (firstThreeDigits123 === "123") {
        console.log(
          "STATUS -- User Entered 123 as an area code -- checkerPhoneNumber()"
        );
        return false;
      }

      let middleThreeDigits123 = sanitizedNumber.slice(3, 6);
      if (middleThreeDigits123 === "123") {
        console.log(
          "STATUS -- User Entered 123 as local code -- checkerPhoneNumber()"
        );
        return false;
      }

      let lastFour1234 = sanitizedNumber.slice(6, 10);
      console.log(lastFour1234);
      if (lastFour1234 === "1234") {
        console.log(
          "STATUS -- User Entered 1234 as last 4 digits -- checkerPhoneNumber()"
        );
        return false;
      }

      return true;
    },
    validatorZipCode(completion) {
      if (this.checkerZipcode()) {
        // SUCCESS
        if (typeof completion === "function") {
          console.log(
            "SUCCESS -- This is a valid ZIP code -- validatorZipCode()"
          );
          completion();
        }
      } else {
        // FAIL
        console.log(
          "ERROR -- This is not a valid ZIP code -- validatorZipCode()"
        );
        this.errorMessageDescription = `*Please enter a valid U.S ZIP code`;
        this.errorMessage = true;
        this.loading = false;
      }
    },
    checkerZipcode() {
      let zipCode = this.formData.zipcode;

      if (!zipCode) {
        console.log("Error: zipCode is undefined or null");
        return false;
      }

      // Ensure zipCode is a string
      if (typeof zipCode !== "string") {
        console.log("Error: zipCode is not a string");
        return false;
      }

      let zipCodePattern = /^\d{5}$/;

      // Test the zip code against the pattern
      if (!zipCodePattern.test(zipCode)) {
        console.log("Error: zipCode does not match the pattern");
        return false;
      }

      // Extract the first three digits
      let firstThreeDigits000 = zipCode.slice(0, 3);
      if (firstThreeDigits000 === "000") {
        console.log(
          "STATUS -- User Entered 000 as the beginning digits -- checkerZipCode()"
        );
        return false;
      }
      return true;
    },
  },
};
</script>
